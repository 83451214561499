import React, { useState, useRef } from "react";

const radioData = [
  { id: 1, value: "problems", name: "One Problem", description: "" },
  {
    id: 2,
    value: "problems",
    name: "Two or More Problems",
    description: "",
  },
  { id: 3, value: "level", name: "Acute Problem", description: "" },
  { id: 4, value: "level", name: "Chronic Problem", description: "" },
  {
    id: 5,
    value: "rating",
    name: "Acute Uncomplicated",
    description: "",
  },
  { id: 6, 
    value: "rating", 
    name: "Acute Uncomplicated Illness Requiring inpat/obs", 
    name1: "Mild Severity, Stable",
    description: "",
  },
    
  {
    id: 7,
    value: "rating",
    name: "Severe (life/limb threat)",
    description: "",
  },
  {
    id: 8,
    value: "cat1_1",
    name: "Review of Prior Notes From Each Unique Source",
    description: "",
  },
  {
    id: 9,
    value: "cat1_2",
    name: "Review of the Results of Each Unique Test",
    description: "",
  },
  {
    id: 10,
    value: "cat1_3",
    name: "Ordering of Each Unique Test",
    description: "",
  },
  {
    id: 11,
    value: "cat1a",
    name: "Assessment Required Independent Historian",
    description: "Did you talk to family/EMS/bystander?",
  },
  {
    id: 12,
    value: "cat2",
    name: "Independent Interpration of Tests",
    description:
      "Interpret Test Performed by Another Physician/Ancillary Staff",
  },
  {
    id: 13,
    value: "cat3",
    name: "Discussion of Management or Test Interpretation",
    description: "Consult Anyone About Results and Management?",
  },
  {
    id: 14,
    value: "complication",
    name: "Minimal",
    description: "Minimal Risk of Complication From Further Treatment",
  },
  {
    id: 15,
    value: "complication",
    name: "Low",
    description: "Low Risk of Complications from Treatment or Lack Thereof",
  },
  {
    id: 16,
    value: "complication",
    name: "Moderate",
    description:
      "Moderate Chance (SDH, surgery discussion, prescription management)",
  },
  {
    id: 17,
    value: "complication",
    name: "High",
    description:
      "High Chance (drug therapy requiring monitoring, surgery, hospitalization or obs, DNR, IV/IM controlled substance)",
  },
  {
    id: 18,
    value: "rating",
    name: "Minimal Severity",
    description: "",
  },
  {
    id: 19,
    value: "rating",
    name: "Complicated Injury",
    name1: "Chronic w/ Exacerbation/Progression/Side Effect",
    name2: "Undiagnosed w/ Uncertain Prognosis",
    name3: "Illness with systemic symptoms",
    description: "",
  },
];

const navigation = [
  {
    name: 'GitHub',
    href: 'https://github.com/Hartman5',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]

console.log('Hey! You Dont Belong Here!')

export default function App() {
  var CompA = useRef(0);
  var CompA1 = useRef(0);
  var CompB = useRef(0);
  var CompC = useRef(0);
  var ProbCount = useRef(0);
  var ProbSev = useRef(0);
  var ProbAC = useRef(0);
  

  var [value1, setValue1] = useState(2);
  var [value2, setValue2] = useState(2);
  var [value3, setValue3] = useState(2);
  var [final, setFinal] = useState(2);

  function handleClick() {
    window.location.reload();
  }

  function handleClick2() {
    window.location.href = "https://www.amazon.com/stores/Brian-Hartman/author/B09WD467GH?maas=maas_adg_CB7523912F32ACA7CE1B8127EBC05C76_afap_abs&ref_=aa_maas&tag=maas?tag=brianhartma08-20";
  }
  
  function handleChange(event) {
    var newID = event.target.id;
    
  // coloumn 1
  if (newID === "One Problem") {
    ProbCount.current = 1;
  }
  if (newID === "Two or More Problems") {
    ProbCount.current = 2;
  }

  if (newID === "Acute Problem") {
    ProbAC.current = 1
  }
  if (newID === "Chronic Problem") {
    ProbAC.current = 2
  }

  if (newID === "Minimal Severity") {
    ProbSev.current = 2
  }
  if (newID === "Acute Uncomplicated Illness Requiring inpat/obs") {
    ProbSev.current = 3
  }
  if (newID === "Complicated Injury") {
    ProbSev.current = 4
  }
  if (newID === "Severe (life/limb threat)") {
    ProbSev.current = 5
  }

  if (ProbCount.current===0) {
    setValue1(5);
  }

  if (ProbCount.current===1) {
    /* One Problem */
    if (ProbAC.current<=1){
      /* Severity Acute or not entered */
      if (ProbSev.current<=2) {
        setValue1(2);
      }
      if (ProbSev.current===3) {
        setValue1(3);
      }
      if (ProbSev.current===4) {
        setValue1(4);
      }
      if (ProbSev.current===5) {
        setValue1(5);
      }
    }
    if (ProbAC.current===2) {
      /* Severity Chronic */
      if (ProbSev.current<=3) {
        setValue1(3);
      }
      if (ProbSev.current===4) {
        setValue1(4);
      }
      if (ProbSev.current===5) {
        setValue1(5);
      }
    }

  }
  if (ProbCount.current===2) {
    /* Two or More Problems */
    if (ProbSev.current<=2) {
      if (ProbAC.current===1) {
        setValue1(3);
      }
      else {
        setValue1(4)
      }
    }
    if (ProbAC.current===3) {
        setValue1(4);
      }  
  
    if (ProbSev.current===4) {
      setValue1(4);
    }
    if (ProbSev.current===5) {
      setValue1(5);
    }
  }
    // coloumn 2
    if (newID === "Review of Prior Notes From Each Unique Source") {
      CompA.current += 1;
    }
    if (newID === "Review of the Results of Each Unique Test") {
      CompA.current += 1;
    }
    if (newID === "Ordering of Each Unique Test") {
      CompA.current += 1;
    }
    if (newID === "Discussion of Management or Test Interpretation") {
      CompC.current = 1;
    }
    if (newID === "Independent Interpration of Tests") {
      CompB.current = 1;
    }
    if (newID === "Assessment Required Independent Historian") {
      CompA1.current = 1;
    }
    var CompScore = 2;
    var CompAA1 = CompA.current + CompA1.current;
    if (CompB.current===1){
      CompScore = 4;
      if (CompC.current===1){
        CompScore = 5;
      }
      if (CompC.current===0) {
        if (CompAA1===1){
        CompScore = 4;
      } 
        if (CompAA1===2){
        CompScore = 4;
      }
        if (CompAA1>=3){
      CompScore = 5;
      }
    }
    }
    if (CompB.current===0){
      if (CompC.current===1){
        CompScore = 4;
        if (CompAA1>=3){
          CompScore = 5;
        }
        else if (CompAA1===2){
          CompScore = 4;
        }
      }  
      else if (CompC.current===0){
        if (CompAA1===1){
          CompScore = 2;
        }
        if (CompAA1===2){
          CompScore = 3;
        }        
        if (CompAA1>=3){
          CompScore = 4;
        }
      }
      else {
        if (CompAA1===2)
        {
          CompScore = 3;
        }
      }
  }
  setValue2(CompScore);
  // coloumn 3
  if (newID === "Minimal") {
    setValue3(2);
  }
  if (newID === "Low") {
    setValue3(3);
  }
  if (newID === "Moderate") {
    setValue3(4);
  }
  if (newID === "High") {
    setValue3(5);
  }
  }
  setTimeout(() => {
    var array = [value1, value2, value3];
    array.sort();
    console.log(array)
    setFinal(array[1]);
  }, 500);
  return (
    <div>
      <div className="relative px-4 pt-4 pb-2 sm:px-6 lg:px-8 lg:pt-4 lg:pb-0">
        <div className="absolute inset-0">
          <div className="h-1/3 bg-white sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              E/M Calculator | Final Score: 9928{final}
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
              Calculate Your E/M Level (Educational Purposes Only)
            </p>
          </div>
          <div className="mx-auto mt-6 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            <div>
              <fieldset>
                <legend className="sr-only">Plan</legend>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Number/Complexity of Problems
                </h2>
                <br />
                <div className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[0].name}
                        onChange={handleChange}
                        name={radioData[0].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[0].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[0].name}
                      </label>
                      <p
                        id={`${radioData[0].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[0].description}
                      </p>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[1].name}
                      onChange={handleChange}
                      name={radioData[1].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={radioData[1].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[1].name}
                    </label>
                    <p
                      id={`${radioData[1].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[1].description}
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">
                      Level
                    </span>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[2].name}
                      onChange={handleChange}
                      name={radioData[2].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={radioData[2].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[2].name}
                    </label>
                    <p
                      id={`${radioData[2].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[2].description}
                    </p>
                  </div>
                </div>
                <br />
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[3].name}
                      onChange={handleChange}
                      name={radioData[3].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={radioData[3].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[3].name}
                    </label>
                    <p
                      id={`${radioData[3].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[3].description}
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">
                      Rating
                    </span>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[17].name}
                      onChange={handleChange}
                      name={radioData[17].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={radioData[17].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[17].name}
                    </label>
                    <p
                      id={`${radioData[17].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[17].description}
                    </p>
                  </div>
                </div>
                <br />
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[5].name}
                      onChange={handleChange}
                      name={radioData[5].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                  <label
                      htmlFor={radioData[5].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[5].name}
                    </label>
                    <br />
                    <label
                      htmlFor={radioData[5].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[5].name1}
                    </label>
                    <p
                      id={`${radioData[5].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[5].description}
                    </p>
                  </div>
                </div>
                <br />
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[18].name}
                      onChange={handleChange}
                      name={radioData[18].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                  <label
                      htmlFor={radioData[18].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[18].name}
                    </label>
                    <br/>
                    <label
                      htmlFor={radioData[18].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[18].name1}
                    </label>
                    <br/>
                    <label
                      htmlFor={radioData[18].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[18].name2}
                    </label>
                    <br/>
                    <label
                      htmlFor={radioData[18].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[18].name3}
                    </label>
                    <p
                      id={`${radioData[18].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[18].description}
                    </p>
                  </div>
                </div>
                <br />
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={radioData[6].name}
                      onChange={handleChange}
                      name={radioData[6].value}
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={radioData[6].value}
                      className="font-medium text-gray-700"
                    >
                      {radioData[6].name}
                    </label>
                    <p
                      id={`${radioData[6].value}-description`}
                      className="text-gray-500"
                    >
                      {radioData[6].description}
                    </p>
                  </div>
                </div>
                <br />
              </fieldset>
            </div>
            <div>
              <fieldset>
                <legend className="sr-only">Plan</legend>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Data Analysis/Complexity Review
                </h2>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">
                      Cat 1
                    </span>
                  </div>
                </div>
                <div className="space-y-5">
                  <div className="relative flex items-start mt-2">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[7].name}
                        onChange={handleChange}
                        name={radioData[7].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[7].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[7].name}
                      </label>
                      <p
                        id={`${radioData[7].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[7].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[8].name}
                        onChange={handleChange}
                        name={radioData[8].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[8].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[8].name}
                      </label>
                      <p
                        id={`${radioData[8].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[8].description}
                      </p>
                    </div>
                  </div>
                  <div
                    key={radioData[9].value}
                    className="relative flex items-start"
                  >
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[9].name}
                        onChange={handleChange}
                        name={radioData[9].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[9].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[9].name}
                      </label>
                      <p
                        id={`${radioData[9].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[9].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500">
                        Cat 1a
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[10].name}
                        onChange={handleChange}
                        name={radioData[10].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[10].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[10].name}
                      </label>
                      <p
                        id={`${radioData[10].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[10].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500">
                        Cat 2
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[11].name}
                        onChange={handleChange}
                        name={radioData[11].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[11].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[11].name}
                      </label>
                      <p
                        id={`${radioData[11].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[11].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500">
                        Cat 3
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[12].name}
                        onChange={handleChange}
                        name={radioData[12].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[12].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[12].name}
                      </label>
                      <p
                        id={`${radioData[12].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[12].description}
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset>
                <legend className="sr-only">Plan</legend>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Risk of Complications or M/M From Management
                </h2>
                <br />
                <div className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[13].name}
                        onChange={handleChange}
                        name={radioData[13].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[13].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[13].name}
                      </label>
                      <p
                        id={`${radioData[13].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[13].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[14].name}
                        onChange={handleChange}
                        name={radioData[14].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[14].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[14].name}
                      </label>
                      <p
                        id={`${radioData[14].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[14].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[15].name}
                        onChange={handleChange}
                        name={radioData[15].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[15].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[15].name}
                      </label>
                      <p
                        id={`${radioData[15].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[15].description}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={radioData[16].name}
                        onChange={handleChange}
                        name={radioData[16].value}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={radioData[16].value}
                        className="font-medium text-gray-700"
                      >
                        {radioData[16].name}
                      </label>
                      <p
                        id={`${radioData[16].value}-description`}
                        className="text-gray-500"
                      >
                        {radioData[16].description}
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          type="button"
          onClick={handleClick}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Reset Data
        </button>
      </div>
      <div className="bg-white">
        <div className="mt-1 bg-white pb-6 sm:pb-6">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-white" />
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-4xl">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Section 1
                    </dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">
                      {value1}
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Section 2
                    </dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">
                      {value2}
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                      Section 3
                    </dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">
                      {value3}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 xl:py-36">
      <div className="mx-auto max-w-max lg:max-w-7xl">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="max-w-prose text-base lg:max-w-none">
            <h2 className="font-semibold leading-6 text-indigo-600">Background</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Who We Are
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block md:[overflow-anchor:none]"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
          </svg>
          <svg
            className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                <p>
                  This site was developed in a few days with several rounds of facepalms as we tried to put government logic into code. 
                  E/M Calculator is based on the new CMS guidelines for coding ED charts and is an educational guide only. Do not expect
                  to reference this page when challenging Medicare reimbursement or in defense of a RAC audit ;)
                </p>
                <p>
                <b>Brian Hartman</b><br/>
                  Board Certified Emergency Medicine Physician praciting in Indiana. I am also a budding novelist and have a medical thriller trilogy in print.
                  If you enjoy this site and find it helpful, or just want to throw me a bone, please consider purchasing one of my books.
                  </p>
                  <b>Evan Hartman</b><br/>
                  Middle School student and coding enthusiast. Creating projects in my free time and learning new skills. https://github.com/hartman5 
                  <p>  
                  <b><i>Redemption</i>, <i>Deception</i>, and <i>Vengeance</i></b> are the medical thrillers. My psychological romance drama, <b><i>It Happened In The Loft</i></b>, is due out early January 2023.
                </p>
              </div>
              <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0">
              <p>
                <b>v1.0.0</b><br/>
                First release of the E/M Calculator. This is a work in progress and will be updated as we find more bugs and add more features.
                </p>
              </div>
                <div align="center" className="mt-8 inline-flex rounded-md shadow">
              <a
                onClick={handleClick2}
                className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
              >
                View on Amazon
              </a>         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <footer className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-base text-gray-400">&copy; 2022 Node Solutions, LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
    <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      ></div>
    </div>
  );
}